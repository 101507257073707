import React, { FC } from "react"

interface LoggedOutProps {
  children: React.ReactNode
}

const LoggedOut: FC<LoggedOutProps> = ({ children }) => {
  return <>{children}</>
}

export default LoggedOut
