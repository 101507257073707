export const QueryParams = {
  categoryName: "categoryName",
  dataType: "dataType",
  displayName: "displayName",
  subcategoryId: "subcategoryId",
  subcategoryName: "subcategoryName",
  threadId: "threadId",
  units: "units",
  conversationStarterId: "conversationStarterId",
  type: "type",
  metricName: "metricName",
} as const

export const paramThreadIdNew = "new"
