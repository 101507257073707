import { datadogRum } from "@datadog/browser-rum"
import { User } from "@my/api"
import { Environment } from "@my/config/src/environment"
import { nativeApplicationVersion } from "expo-application"

let initialized = false

export function initDatadogRUM() {
  if (!initialized) {
    initialized = true
    datadogRum.init({
      applicationId: Environment.DATADOG_APP_ID,
      clientToken: Environment.DATADOG_CLIENT_TOKEN,
      site: "datadoghq.com",
      service: "healthcoach-web",
      env: Environment.ENV,
      version: Environment.COMMIT_SHA,
      sessionSampleRate: 100,
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      beforeSend: (error) => {
        // Ignore all errors from localhost where ENV is not set
        return Environment.ENV !== ""
      },
    })
    datadogRum.setGlobalContextProperty("buildNumber", nativeApplicationVersion)
  }
}

export function setDatadogUser(user: User) {
  datadogRum.setUser({
    id: user.auth0Id,
  })
}
