import { QueryClient } from "@tanstack/react-query"
import { useMemo } from "react"

const MILLISECONDS_PER_SECOND = 1000
const MILLISECONDS_PER_MINUTE = MILLISECONDS_PER_SECOND * 60
const MILLISECONDS_PER_HOUR = MILLISECONDS_PER_MINUTE * 60

const TWENTY_SECONDS = MILLISECONDS_PER_SECOND * 20
const FIVE_MINUTES = MILLISECONDS_PER_MINUTE * 5
const TWENTY_MINUTES = MILLISECONDS_PER_MINUTE * 20
const ONE_HOUR = MILLISECONDS_PER_HOUR

export const CacheTimes = {
  NONE: TWENTY_SECONDS, // We don't want to use a true 0 because it's useful to debounce parallel requests
  SHORT: FIVE_MINUTES,
  MEDIUM: TWENTY_MINUTES,
  LONG: ONE_HOUR,
  DEFAULT: TWENTY_SECONDS,
} as const

export function useSetupQueryClient() {
  const client = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: CacheTimes.DEFAULT,
          },
        },
      }),
    [],
  )
  return client
}
