export type Asset = ReturnType<typeof require>

export const Icons: Record<string, Asset> = {
  AppleHealth: require("./icons/AppleHealth.png"),
} as const

export const Images: Record<string, Asset> = {
  HomeBackgroundWeb: require("./images/HomeBackground-web.png"),
  HomeBackgroundNative: require("./images/HomeBackground-native.png"),
  HomeScreenCheckIn: require("./images/HomeScreenCheckIn.png"),
  RainbowGradient: require("./images/rainbowGradientBackground.png"),
} as const
