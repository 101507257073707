import { Button } from "@my/ui/src/buttons/Button"
import { HealthyLogo } from "app/assets/images/HealthyLogo"
import {
  Image,
  ScrollView,
  View,
  SizableText,
  useWindowDimensions,
  useMedia,
  YStack,
  H1,
} from "tamagui"
import { AppFooter } from "app/features/shared/appFooter/AppFooter"
import { ProgramsList } from "./ProgramsList"
import { Images, loadImageAsset } from "app/assets"
import { useAuth } from "@my/api"
import { GradientBgCircle } from "app/assets/images/GradientBgCircle"
import { trackButtonClicked } from "app/telemetry"
import { Platform } from "react-native"
import { isMobileDevice } from "@my/utils/src/universalTypes/typeHelper"

export function ProgramsLandingPage() {
  const { gtLg, sm, xs, xxs } = useMedia()
  const { height: screenHeight, width: screenWidth } = useWindowDimensions()
  const { signup, login, isAuthenticated } = useAuth()

  const RainbowBackground = loadImageAsset(Images.RainbowGradient)

  return (
    <ScrollView contentContainerStyle={{ minHeight: screenHeight }} position="relative">
      <View position="absolute" top={0} left={0} width="100%">
        <Image
          source={{
            uri: RainbowBackground as string,
            height:
              xxs ? 370
              : xs ? 300
              : sm ? 320
              : gtLg ? 620
              : 500,
            width: screenWidth,
          }}
        />
        <View position="absolute" bottom={-1} zIndex={0} width="100%">
          <GradientBgCircle />
        </View>
      </View>

      <View
        alignContent="center"
        alignItems="center"
        flexDirection="row"
        justifyContent="space-between"
        paddingHorizontal="$base"
        paddingVertical={24}
        width="100%"
        maxWidth="100%"
        marginHorizontal="auto"
      >
        <HealthyLogo style={{ height: 44, width: 130 }} />

        <Button testID="login-flow-login" variant="secondary" onPress={() => login()}>
          Login
        </Button>
      </View>

      <YStack justifyContent="center" gap="$sm" alignItems="center">
        <H1
          textAlign="center"
          color="$colorTitle"
          fontFamily="$fields"
          fontSize={gtLg ? "$3" : "$2"}
          lineHeight={gtLg ? "$3" : "$2"}
          minHeight={gtLg ? "$11" : "$7"}
        >
          Get Healthy.
        </H1>
        <SizableText
          textAlign="center"
          color="$colorTitle"
          fontSize={
            gtLg ? 22
            : xxs ?
              16
            : 16
          }
          lineHeight={
            gtLg ? 26
            : xxs ?
              17
            : 19
          }
        >
          Log, track, and learn one conversation at a time
        </SizableText>
      </YStack>

      <View alignContent="center" alignItems="center" justifyContent="center" marginTop="$lg">
        <Button
          testID="login-flow-get-started"
          variant="action"
          color="$color"
          width={sm ? "$15" : "$18"}
          height="$7"
          maxWidth="100%"
          boxShadow="0 4px 12px 1px rgba(33,33,33,0.18)"
          onPress={() => {
            signup()
            trackButtonClicked("SignUp", "GetHealthy", {
              logged_out: !isAuthenticated,
              platform: Platform.OS,
              mobile_web: isMobileDevice(),
            })
          }}
          size={sm ? "$1" : "$5"}
        >
          Sign up to Get Healthy
        </Button>
      </View>

      <View paddingVertical="$xl" flex={1} maxWidth={1300} margin="auto">
        <ProgramsList />
      </View>
      <AppFooter />
    </ScrollView>
  )
}
