import { User } from "@my/api"
import { useAuth } from "@my/api/src/auth/useAuth"
import { useHttpClient } from "@my/api/src/http/useHttpClient"
import { useUserStore } from "app/global-state/userState"
import { useStoredBoolean } from "app/storage/useStorage"
import { useUtm } from "app/utm/useUtm"
import { useEffect, useMemo } from "react"
import { isWeb } from "tamagui"

type UsePostUserProps = {
  setWaitlist: (waitlist: boolean) => void
  setIsGeoblocked: (isGeoblocked: boolean) => void
}

export const getStartedShownKey = "get-started-shown"

export function usePostUser({ setWaitlist, setIsGeoblocked }: UsePostUserProps) {
  const httpClient = useHttpClient()
  const { isAuthenticated } = useAuth()
  const { utmParameters, isLoading: isUtmLoading } = useUtm()
  const { hasHitUsersEndpoint, setHasHitUsersEndpoint, setUser } = useUserStore()
  const [getStartedShown] = useStoredBoolean(getStartedShownKey, isWeb)

  const shouldFetchUser = useMemo(() => {
    return (
      getStartedShown &&
      isAuthenticated &&
      httpClient.isInitialized &&
      !isUtmLoading &&
      !hasHitUsersEndpoint
    )
  }, [
    getStartedShown,
    isAuthenticated,
    httpClient.isInitialized,
    isUtmLoading,
    hasHitUsersEndpoint,
  ])

  useEffect(() => {
    if (shouldFetchUser) {
      const forbiddenErrorHandler = () => {
        setWaitlist(true)
        return true
      }
      const geoblockedErrorHandler = () => {
        setIsGeoblocked(true)
        return true
      }
      httpClient
        .post<User>(
          "/users",
          { utmParameters },
          {
            apiVersion: 2,
            errorHandlers: {
              onForbidden: forbiddenErrorHandler,
              onGeoblocked: geoblockedErrorHandler,
            },
          },
        )
        .then((user) => {
          setUser(user)
          setHasHitUsersEndpoint(true)
        })
    }
  }, [httpClient, shouldFetchUser])
}
