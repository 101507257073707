import { Subscription, useHttpClient } from "@my/api"
import { useSubscriptionStore } from "../global-state/subscriptionsStore"
import { useCallback } from "react"
import { writeToDebug } from "@my/config/src/debug"

export function useFetchSubscriptions() {
  const { fetchSubscriptions } = useSubscriptionStore()

  const httpClient = useHttpClient()

  return useCallback(() => {
    return fetchSubscriptions(httpClient)
  }, [fetchSubscriptions, httpClient])
}

/**
 * Represents the response from subscribing or unsubscribing to a module.
 */
export type SubscriptionResponse = {
  /**
   * Indicates whether the subscription change was successful.
   */
  success: boolean

  /**
   * Contains an error message if the subscription change failed, otherwise null.
   */
  error: string | null
}

/**
 * Custom hook to subscribe or unsubscribe to a module.
 *
 * This hook provides a function to either subscribe or unsubscribe from a module.
 * It ensures that the user cannot unsubscribe if they only have one subscription left.
 * It also prevents subscribing to a module that the user is already subscribed to.
 *
 * @returns {Function} A function that takes a boolean indicating whether to subscribe or unsubscribe,
 * and a moduleId string. The function returns a Promise that resolves to a SubscriptionResponse.
 */
export function useToggleSubscription() {
  const { subscriptions } = useSubscriptionStore()
  const addSubscriptions = useAddSubscription()
  const removeSubscriptions = useRemoveSubscription()

  return useCallback(
    async (subscribe: boolean, moduleId: string): Promise<SubscriptionResponse> => {
      const isSubscribed = (subscriptions ?? []).some(
        (subscription) => subscription.moduleId === moduleId,
      )

      if (subscribe && isSubscribed) {
        writeToDebug(`Already subscribed to module (${moduleId}), skipping`)
        return { success: true, error: null }
      }

      if (!subscribe && subscriptions.length === 1) {
        return { success: false, error: "You must have at least one subscription" }
      }

      if (subscribe) {
        return await addSubscriptions(moduleId)
      } else {
        return await removeSubscriptions(moduleId)
      }
    },
    [subscriptions, addSubscriptions, removeSubscriptions],
  )
}

/**
 * Adds a single subscription by module ID.
 * Returns a function that can be called to trigger the addition.
 */
export function useAddSubscription() {
  const httpClient = useHttpClient()
  const { addSubscriptions, setError } = useSubscriptionStore()

  return useCallback(
    async (moduleId: string): Promise<SubscriptionResponse> => {
      try {
        const response = await httpClient.post<Subscription>(`/subscriptions/modules/${moduleId}`)
        addSubscriptions([{ ...(response as Subscription) }])
        writeToDebug(`Subscription added for module ID: ${moduleId}`)
        return { success: true, error: null }
      } catch (error) {
        const errorMessage = `Failed to add subscription: ${error}`
        setError(errorMessage)
        return { success: false, error: errorMessage }
      }
    },
    [httpClient, addSubscriptions, setError],
  )
}

/**
 * Removes a single subscription by module ID.
 * Returns a function that can be called to trigger the removal.
 */
export function useRemoveSubscription() {
  const httpClient = useHttpClient()
  const { removeSubscriptions, setError } = useSubscriptionStore()

  return useCallback(
    async (moduleId: string): Promise<SubscriptionResponse> => {
      try {
        const response = await httpClient.delete<Subscription>(`/subscriptions/modules/${moduleId}`)
        const removedSubscription = response as Subscription
        if (removedSubscription) {
          removeSubscriptions([removedSubscription.id])
          writeToDebug(`Subscription removed for module ID: ${moduleId}`)
          return { success: true, error: null }
        } else {
          const errorMessage = "Failed to remove subscription"
          console.error("Failed to remove subscription: ", response, httpClient.lastResponseStatus)
          setError(errorMessage)
          return { success: false, error: errorMessage }
        }
      } catch (error) {
        const errorMessage = `Failed to remove subscription for module ID ${moduleId}: ${error}`
        console.error(errorMessage)
        setError(errorMessage)
        return { success: false, error: errorMessage }
      }
    },
    [httpClient, removeSubscriptions, setError],
  )
}
