import { IconName } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { useTheme } from "tamagui"
import { ColorType, IconType, translateColor } from "./utils"

export type FontAwesomeSizes = FontAwesomeIconProps["size"]
export interface IconProps {
  icon: IconName
  size?: FontAwesomeSizes | IconSize | number
  active?: boolean
  hovering?: boolean
  color?: ColorType
  activeColor?: ColorType
  hoveringColor?: ColorType
}

export enum IconSize {
  Small = "sm",
  Medium = "lg",
  Large = "xl",
  Xlarge = "2xl",
  Mega = "6x",
}

export function Icon({
  icon,
  size = IconSize.Medium,
  active = false,
  hovering = false,
  color = "$icon",
  activeColor = "$icon",
  hoveringColor = "$icon",
  ...props
}: IconProps) {
  const theme = useTheme()
  const [isHovering, setIsHovering] = useState(hovering)

  let myColor: string | undefined = undefined

  if (active) {
    myColor = translateColor(activeColor, theme)
  } else if (isHovering) {
    myColor = translateColor(hoveringColor, theme)
  } else {
    myColor = translateColor(color, theme)
  }

  return (
    <FontAwesomeIcon
      icon={{ prefix: active ? IconType.Solid : IconType.Regular, iconName: icon }}
      size={size as FontAwesomeSizes}
      color={myColor}
      fill={myColor}
      fixedWidth
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      {...props}
    />
  )
}
