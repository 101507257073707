import { ProgramResponse, useHttpClient } from "@my/api"
import { writeToDebug } from "@my/config/src/debug"
import { useQuery } from "@tanstack/react-query"
import { useCallback } from "react"
import { CacheTimes } from "../hooks/useSetupQueryClient"

export const usePrograms = () => {
  const httpClient = useHttpClient()

  const fetchModules = useCallback(async () => {
    const response = await httpClient.get<ProgramResponse>(`/modules`, { requireAuth: false })
    writeToDebug("Modules response: ", response)

    if (!response) {
      throw new Error("Failed to fetch programs")
    }

    return response.modules
  }, [httpClient])

  return useQuery({
    queryKey: ["programs"],
    queryFn: fetchModules,
    gcTime: CacheTimes.SHORT,
    staleTime: CacheTimes.SHORT, // These shouldn't change hardly ever, we can cache for at least 5 mins
  })
}
