import { FC, useEffect, useState, useRef, useCallback, useMemo } from "react"
import { SizableText, Separator, Stack, View, H6, useMedia, ImageProps } from "tamagui"
import { ModulesLoader, ModulesLoaderSm } from "./ProgramsLoader"
import { Pressable } from "react-native"
import { useFeatureFlag } from "app/feature-flags/useFeatureFlag"
import { useRouter } from "solito/router"
import { ProgramStatus } from "@my/api/src/types/programs"
import { ImageWithPlaceholder } from "@my/ui/src/placeholders/PlaceholderImage"
import { useFetchSubscriptions } from "../../hooks/useSubscriptions"
import { useSubscriptionStore } from "app/global-state/subscriptionsStore"
import { mixpanel, trackButtonClicked } from "app/telemetry"
import { Routes } from "app/routing"
import { useAuth } from "@my/api"
import { usePrograms } from "app/queries/programQueries"

export const ProgramsList: FC = () => {
  const { isAuthenticated } = useAuth()
  const { sm, md, lg } = useMedia()
  const router = useRouter()
  const modulesUnpublishedFlag = useFeatureFlag("modules-view-unpublished", false)
  const { data: programs = [], isPending, isError } = usePrograms()
  const { getSubscriptionByProperty } = useSubscriptionStore()

  const [loadedImages, setLoadedImages] = useState<Record<number, boolean>>({})
  const batchUpdateRef = useRef<NodeJS.Timeout>()

  const pageName = useMemo(() => {
    return !isAuthenticated ? "GetHealthy" : "Explore"
  }, [isAuthenticated])

  mixpanel.trackOnMount({ page_name: pageName, logged_out: !isAuthenticated })

  // Memoize this to prevent recreation
  const loaderItems = useMemo(() => {
    if (sm) return 5
    if (md) return 2
    if (lg) return 3
    return 4
  }, [sm, md, lg])

  const loaderList = useMemo(() => Array.from(Array(loaderItems).keys()), [loaderItems])

  const fetchSubscriptions = useFetchSubscriptions()

  // Batch update loadedImages. Prevents a flood of re-renders.
  const handleImageLoad = useCallback((index: number) => {
    setLoadedImages((prev) => {
      // If this image is already marked as loaded, don't update
      if (prev[index]) return prev

      // Clear any existing timeout
      if (batchUpdateRef.current) {
        clearTimeout(batchUpdateRef.current)
      }

      // Set a new timeout to batch updates
      batchUpdateRef.current = setTimeout(() => {
        setLoadedImages((current) => ({ ...current, [index]: true }))
      }, 100) // Batch updates within 100ms

      return prev // Don't update state immediately
    })
  }, [])

  // Cleanup timeout on unmount
  useEffect(() => {
    return () => {
      if (batchUpdateRef.current) {
        clearTimeout(batchUpdateRef.current)
      }
    }
  }, [])

  useEffect(() => {
    if (isAuthenticated) {
      fetchSubscriptions()
    }
  }, [isAuthenticated])

  const renderLoader = (key: number | string) => {
    return sm ? <ModulesLoaderSm key={key} /> : <ModulesLoader key={key} />
  }

  const SubscribedImageBanner = ({ programId }: { programId: string }) => {
    const subscription = getSubscriptionByProperty("moduleId", programId)

    if (!subscription) {
      return null
    }

    return (
      <View
        backgroundColor="rgba(0,0,0,0.6)"
        borderTopLeftRadius="$6"
        borderBottomRightRadius="$6"
        borderTopRightRadius={sm ? "$3" : 0}
        top={0}
        left={0}
        padding="$xs"
        position="absolute"
        maxWidth="100%"
      >
        <SizableText
          fontSize={sm ? "$3" : "$4"}
          fontWeight="400"
          color="$colorInverse"
          textTransform="uppercase"
          textWrap="nowrap"
          textOverflow="ellipsis"
        >
          Subscribed
        </SizableText>
      </View>
    )
  }

  const style = useMemo(
    () =>
      ({
        marginHorizontal: isAuthenticated ? undefined : "auto",
        width: isAuthenticated ? undefined : "100%",
        maxWidth: 1064,
      }) as const,
    [isAuthenticated],
  )

  // Memoize the programs rendering
  const renderedPrograms = useMemo(() => {
    return programs.map((program, index) => {
      const showProgram =
        program.status !== ProgramStatus.Draft ||
        (modulesUnpublishedFlag && program.status === ProgramStatus.Draft)

      if (!showProgram) {
        return null
      }

      const imageProps = {
        alignSelf: sm ? "center" : "flex-start",
        borderRadius: "$6",
        height: sm ? 110 : 138,
        width: sm ? 110 : 234,
        maxWidth: "100%",
        source: {
          uri: sm ? program.thumbnailUrl : program.imageUrl,
          height: sm ? 110 : 138,
          width: sm ? 110 : 234,
        },
      } satisfies ImageProps

      const placeholderProps = {
        height: sm ? 110 : 138,
        width: sm ? 110 : 234,
      }

      return (
        <View
          key={program.id}
          width={
            sm ? "100%"
            : md ?
              "$12"
            : lg ?
              "$11"
            : "$10"
          }
          minWidth={sm ? "auto" : 260}
          maxWidth="100%"
          testID="program-card"
        >
          <Pressable
            onPress={() => {
              trackButtonClicked("ProgramListing", pageName, {
                module_id: program.id,
                module_name: program.name,
                position: index + 1,
                logged_out: !isAuthenticated,
              })
              router.push(Routes.createRouteForProgramDetails(program.path))
            }}
            style={({ pressed }) => [
              {
                backgroundColor:
                  pressed ? "$backgroundCardsPress"
                  : program.status === ProgramStatus.Draft ? "$backgroundWarningLight"
                  : "transparent",
              },
            ]}
          >
            <Stack
              borderRadius={sm ? "$0" : "$8"}
              flexDirection={sm ? "row" : "column"}
              flexBasis={index === programs.length - 1 && !sm ? "100%" : "auto"}
              maxWidth="100%"
              overflow="hidden"
              padding="$sm"
              $sm={{ paddingHorizontal: "$0" }}
              transition="background-color 0.4s ease"
              width={sm ? "100%" : 260}
              backgroundColor={
                program.status === ProgramStatus.Draft ? "$backgroundWarningLight" : "$background"
              }
              hoverStyle={{
                cursor: "pointer",
                backgroundColor: "$backgroundCardsHover",
              }}
            >
              <View position="relative" height={sm ? 110 : 138} width={sm ? 110 : 234}>
                <ImageWithPlaceholder
                  key={program.id}
                  index={index}
                  onLoad={(idx) => handleImageLoad(idx ?? 0)}
                  previousImageLoaded={index === 0 || loadedImages[index - 1]}
                  imageProps={imageProps}
                  placeholderProps={placeholderProps}
                />

                <SubscribedImageBanner programId={program.id} />
              </View>

              <View
                marginTop={sm ? 0 : "$sm"}
                flexShrink={1}
                alignSelf={sm ? "center" : "flex-start"}
                $sm={{ marginLeft: "$base" }}
              >
                <H6 fontSize="$4" fontWeight={700} numberOfLines={2}>
                  {program.name}
                </H6>

                <SizableText
                  marginTop="$xxs"
                  fontSize="$4"
                  lineHeight="$3"
                  fontWeight={500}
                  numberOfLines={2}
                >
                  {program.description}
                </SizableText>
              </View>
            </Stack>
          </Pressable>

          {sm && index !== programs.length - 1 && <Separator />}
        </View>
      )
    })
  }, [programs, sm, md, lg, modulesUnpublishedFlag, loadedImages, router])

  return (
    <Stack alignItems="center" width="100%" paddingBottom="$xl">
      <Stack
        flexDirection={sm ? "column" : "row"}
        flexWrap="wrap"
        gap={sm ? "$0" : "$xs"}
        justifyContent={isAuthenticated ? "flex-start" : "center"}
        marginHorizontal={style.marginHorizontal}
        width={style.width}
        maxWidth={style.maxWidth}
        paddingHorizontal={!isAuthenticated && sm ? "$sm" : undefined}
      >
        {isPending || isError || programs.length == 0 ?
          loaderList.map((value, index) => renderLoader(index))
        : renderedPrograms}

        {/* Add spacer blocks to ensure last line is left-aligned. */}
        {loaderList.map((item, index) => (
          <View
            key={index}
            width={
              sm ? "100%"
              : md ?
                "$12"
              : lg ?
                "$11"
              : "$10"
            }
            minWidth={sm ? "auto" : 260}
            maxWidth="100%"
          />
        ))}
      </Stack>
    </Stack>
  )
}
