import { format } from "date-fns"

let debugMode = false
let logTimestamp = false

/**
 * Configures the global debug mode for the application.
 *
 * This function allows enabling or disabling the debug mode globally.
 * When debug mode is enabled, debug messages will be logged to the console.
 *
 * @param {boolean} enabled - A boolean flag to enable or disable debug mode.
 * @example
 * configureDebug(true); // Enables debug mode
 * configureDebug(false); // Disables debug mode
 */
export const configureDebug = (enabled: boolean) => {
  debugMode = enabled
}

/**
 * Checks if debug mode is enabled.
 *
 * @returns {boolean} True if debug mode is enabled, false otherwise.
 */
export const isDebugEnabled = () => {
  return debugMode
}

/**
 * Configures whether to include a timestamp in debug logs.
 *
 * This function allows enabling or disabling the inclusion of timestamps in debug messages.
 * When enabled, each debug message will be prefixed with a formatted timestamp.
 *
 * @param {boolean} enabled - A boolean flag to enable or disable timestamp inclusion in debug logs.
 * @example
 * configureLogTimestamp(true); // Enables timestamp in debug logs
 * configureLogTimestamp(false); // Disables timestamp in debug logs
 */
export const configureLogTimestamp = (enabled: boolean) => {
  logTimestamp = enabled
}

/**
 * Generates a formatted timestamp string.
 *
 * This function returns the current date and time formatted as "MM-dd-yyyy hh:mm:ss a".
 *
 * @returns {string} The formatted timestamp string.
 * @example
 * const timestamp = generateTimestamp();
 * console.log(timestamp); // Outputs: "09-08-2021 04:30:15 pm"
 */
function generateTimestamp(): string {
  return format(new Date(), "MM-dd-yyyy hh:mm:ss a")
}

/**
 * Logs debug information to the console if the provided debug flag is true.
 *
 * This function is used for debugging purposes throughout the application.
 * It only logs the information when the provided debug flag (or the global DEBUG flag if not provided)
 * is set to true, allowing for easy toggling of debug output.
 *
 * @param {boolean} [debugFlag=DEBUG] - Optional debug flag to control logging. Defaults to global DEBUG value.
 * @param {...any[]} args - Any number of arguments to be logged.
 * @example
 * writeToDebug(true, "User logged in", user);
 * writeToDebug(DEBUG_SOCKET, "API response", response);
 */
export function writeToDebug(...args: any[]) {
  if (!debugMode) {
    return
  }

  if (logTimestamp) {
    args.unshift(`${generateTimestamp()} | `)
  }
  console.debug(...args)
}
