export enum ProgramStatus {
  Draft = "draft",
  Published = "published",
}

export type CheckInItem = {
  name: string
  rationale: string
}

/**
 * description: "Struggling with persistent gut symptoms? You’re not alone. \"Navigating Chronic Gut Issues\" is designed to empower you with tools to manage symptoms, identify potential triggers, and find more comfort day-to-day. Through personalized check-ins, symptom tracking, and guided insights, you’ll gain clarity on how lifestyle factors like food, stress, and sleep affect your gut health.\n\nThis program supports you in connecting the dots between what you eat, how you feel, and daily habits—empowering you with knowledge and tips to reduce discomfort and enjoy more good days. Whether it’s occasional bloating, fatigue after meals, or frequent digestive issues, this module helps you find relief, one small step at a time."
 * id: "32f75954-1afe-4006-832f-1dc45120fbac"
 * imageUrl: "mock"
 * name: "Navigating Chronic Gut Issues"
 * path: "Navigating-Chronic-Gut-Issues"
 * status: "published"
 * thumbnailUrl: "mock"
 */
export type Program = {
  id: string
  name: string
  description: string
  status: ProgramStatus
  imageUrl: string
  thumbnailUrl: string
  path: string
  checkInItems: CheckInItem[]
  createdAt: Date | null
  updatedAt: Date | null
}

export type ProgramResponse = {
  modules: Program[]
}
