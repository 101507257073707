import { Subscription, SubscriptionResponse, HttpClient } from "@my/api"
import { create } from "./create"
import { unionBy } from "lodash"
import { writeToDebug } from "@my/config/src/debug"

type SubscriptionProperty = Subscription[keyof Subscription]

interface SubscriptionStore {
  subscriptions: Subscription[]
  historicalSubscriptions: Subscription[]
  error: string | null
  isFetched: boolean
  isFetching: boolean
  /**
   * Stores the current fetch operation's Promise to prevent multiple concurrent requests.
   * When non-null, indicates an active fetch operation is in progress.
   */
  fetchSubscriptionsPromise: Promise<Subscription[] | undefined> | null
  setError: (error: string | null) => void
  setFetched: (fetched: boolean) => void
  setFetching: (fetching: boolean) => void
  setSubscriptions: (subscriptions: Subscription[]) => void
  setHistoricalSubscriptions: (historicalSubscriptions: Subscription[]) => void
  addSubscriptions: (newSubscriptions: Subscription[]) => void
  removeSubscriptions: (subscriptionIds: string[]) => void
  /**
   * Updates the fetchSubscriptionsPromise in the store.
   * Used internally by fetchSubscriptions to track the current request.
   * @param promise - The active fetch Promise, or null when the request completes
   */
  setFetchSubscriptionsPromise: (promise: Promise<Subscription[] | undefined> | null) => void
  getSubscriptionByProperty: (
    property: keyof Subscription,
    value: SubscriptionProperty,
  ) => Subscription | undefined
  getSubscriptionsByProperties: <K extends keyof Subscription>(
    property: K,
    values: Subscription[K][],
  ) => Subscription[]
  /**
   * Fetches subscriptions from the API, ensuring only one request is processed at a time.
   * Returns the existing Promise if a fetch is already in progress.
   *
   * @param httpClient - The HTTP client instance to make the API request
   * @returns Promise<Subscription[] | undefined> - Resolves to the fetched subscriptions
   *
   * @example
   * ```typescript
   * const { fetchSubscriptions } = useSubscriptionStore()
   * const httpClient = useHttpClient()
   *
   * // Fetch subscriptions
   * const subscriptions = await fetchSubscriptions(httpClient)
   * ```
   */
  fetchSubscriptions: (httpClient: HttpClient) => Promise<Subscription[] | null | undefined>
}

export const useSubscriptionStore = create<SubscriptionStore>((set, get) => ({
  subscriptions: [], // A list of current subscriptions
  historicalSubscriptions: [], // A list of current and canceled subscriptions
  error: null,
  isFetched: false,
  isFetching: false,
  fetchSubscriptionsPromise: null,

  setError: (error) => set({ error }),
  setFetched: (fetched) => set({ isFetched: fetched }),
  setFetching: (fetching) => set({ isFetching: fetching }),
  setFetchSubscriptionsPromise: (promise) => set({ fetchSubscriptionsPromise: promise }),

  // Set all subscriptions
  setSubscriptions: (subscriptions) => set({ subscriptions }),
  setHistoricalSubscriptions: (historicalSubscriptions) => set({ historicalSubscriptions }),

  // Add new subscriptions
  addSubscriptions: (newSubscriptions) =>
    set((state) => ({
      subscriptions: unionBy(newSubscriptions, state.subscriptions, "id"),
      historicalSubscriptions: unionBy(newSubscriptions, state.historicalSubscriptions, "id"),
    })),

  // Remove subscriptions by IDs
  removeSubscriptions: (subscriptionIds) =>
    set((state) => {
      const idsToRemove = new Set(subscriptionIds)
      return {
        subscriptions: state.subscriptions.filter((sub) => !idsToRemove.has(sub.id)),
        historicalSubscriptions: state.historicalSubscriptions.map((sub) =>
          idsToRemove.has(sub.id) ? { ...sub, canceledAt: new Date() } : sub,
        ),
      }
    }),
  // Get a subscription by a specific property
  getSubscriptionByProperty: (property, value) => {
    const { subscriptions } = get()
    return subscriptions.find((sub) => sub[property] === value)
  },

  // Get subscriptions by a list of specific property values
  getSubscriptionsByProperties: <K extends keyof Subscription>(
    property: K,
    values: Subscription[K][],
  ) => {
    const { subscriptions } = get()
    return subscriptions.filter((sub) => values.includes(sub[property]))
  },

  fetchSubscriptions: async (httpClient: HttpClient) => {
    const {
      fetchSubscriptionsPromise,
      isFetching,
      setFetchSubscriptionsPromise,
      setFetching,
      setSubscriptions,
      setHistoricalSubscriptions,
      setError,
      setFetched,
    } = get()

    // The following two checks allows us to prevent multiple concurrent requests
    if (fetchSubscriptionsPromise) {
      return fetchSubscriptionsPromise
    }

    if (isFetching) {
      return fetchSubscriptionsPromise
    }

    const promise = (async () => {
      try {
        setFetching(true)
        writeToDebug("Fetching Subscriptions")

        const response = await httpClient.get<SubscriptionResponse>(
          "/subscriptions/modules?include_canceled=true",
        )
        writeToDebug("Subscriptions response: ", response)
        setHistoricalSubscriptions(response?.subscriptions ?? [])
        setSubscriptions((response?.subscriptions ?? []).filter((sub) => sub.canceledAt === null))
        setError(null)
        setFetched(true)
        return response?.subscriptions
      } catch (error) {
        console.error("Failed to fetch subscriptions:", error)
        setError("Failed to fetch subscriptions")
        throw error
      } finally {
        setFetchSubscriptionsPromise(null)
        setFetching(false)
      }
    })()

    setFetchSubscriptionsPromise(promise)
    return promise
  },
}))
