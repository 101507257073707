import { ContentLoader } from "@my/ui/src/contentLoader/ContentLoader"
import { FC } from "react"
import { useWindowDimensions } from "tamagui"
import { Rect } from "react-native-svg"

export const ModulesLoader: FC = (props) => (
  <ContentLoader
    speed={2}
    width={260}
    height={256}
    viewBox="0 0 260 256"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <>
      <Rect x="7" y="0" rx="3" ry="3" width="212" height="138" />

      <Rect x="7" y="150" rx="3" ry="3" width="212" height="18" />
      <Rect x="7" y="174" rx="3" ry="3" width="150" height="18" />

      <Rect x="7" y="202" rx="3" ry="3" width="212" height="14" />
      <Rect x="7" y="220" rx="3" ry="3" width="150" height="14" />
    </>
  </ContentLoader>
)

export const ModulesLoaderSm: FC = (props) => {
  const { width: screenWidth } = useWindowDimensions()
  const maxContentWidth = screenWidth - 100 - 48 - 18

  return (
    <ContentLoader
      speed={2}
      width={screenWidth}
      height={130}
      viewBox={`0 0 ${screenWidth} 130`}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <>
        <Rect x="24" y="13" rx="3" ry="3" width="110" height="110" />

        <Rect x="150" y="13" rx="3" ry="3" width={maxContentWidth} height="18" />
        <Rect x="150" y="37" rx="3" ry="3" width={maxContentWidth * 0.4} height="18" />

        <Rect x="150" y="65" rx="3" ry="3" width={maxContentWidth} height="14" />
        <Rect x="150" y="84" rx="3" ry="3" width={maxContentWidth * 0.7} height="14" />
      </>
    </ContentLoader>
  )
}
