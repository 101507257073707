import { isWeb } from "tamagui"

export const isMobileDevice = () => {
  if (!isWeb) return true
  try {
    // Comprehensive device check including modern devices and tablets
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|Tablet|Touch/i.test(
      navigator?.userAgent ?? "",
    )
  } catch {
    return false
  }
}
