// Define the structure of a single color swatch
export type ColorSwatch = {
  [key: number]: string // Example: { 10: "#fff", 20: "#eee", ... }
}

// Define the structure of the Primitives object
export type Primitives = {
  [colorName: string]: ColorSwatch // Example: { "prawn": { 10: "#fff", ... }, "amber": { ... } }
}

export const prawn: ColorSwatch = {
  10: "#410004",
  20: "#68000C",
  30: "#900C19",
  40: "#B22A2D",
  50: "#D44343",
  60: "#F75C59",
  70: "#FF8983",
  80: "#FFB3AE",
  90: "#FFDAD7",
  93: "#FFE5E3",
  95: "#FFEDEB",
  98: "#FFF8F7",
}

export const amber: ColorSwatch = {
  10: "#341100",
  20: "#552100",
  30: "#783100",
  40: "#9E4300",
  50: "#C55500",
  60: "#EE6800",
  70: "#FF8D4C",
  80: "#FFB691",
  90: "#FFDBCB",
  93: "#FFE6DB",
  95: "#FFEDE6",
  98: "#FFF8F6",
}

export const sand: ColorSwatch = {
  10: "#241A09",
  20: "#3A2F1C",
  30: "#524530",
  40: "#6B5C46",
  50: "#85755E",
  60: "#9F8E76",
  70: "#BBA98F",
  80: "#D7C4A9",
  90: "#F4E0C4",
  93: "#FDE8CC",
  95: "#FFEEDA",
  97: "#FFF8F3",
  98: "#FFFAF6",
  99: "#FFFDFB",
}

export const sol: ColorSwatch = {
  10: "#251A00",
  20: "#3F2E00",
  30: "#5B4300",
  40: "#785900",
  50: "#977100",
  60: "#B78A00",
  70: "#D8A300",
  80: "#FABD00",
  90: "#FFDF9D",
  93: "#FFE8BF",
  95: "#FFEFD4",
  98: "#FFF8F2",
}

export const spruce: ColorSwatch = {
  10: "#001F24",
  20: "#1E373C",
  30: "#3C5255",
  40: "#7EA0A4",
  50: "#96B2B5",
  60: "#B0C5C5",
  70: "#C9D7D6",
  80: "#E3EBE8",
  90: "#E2EBE6",
  93: "#E9F0ED",
  95: "#F1F5F3",
  98: "#F9FAFA",
}

export const fern: ColorSwatch = {
  10: "#002110",
  20: "#00391F",
  30: "#00522F",
  40: "#006D40",
  50: "#008952",
  60: "#02A665",
  70: "#3AC17E",
  80: "#5BDE97",
  90: "#7AFBB1",
  93: "#A0FFC3",
  95: "#C1FFD4",
  98: "#E9FFEC",
}

export const evergreen: ColorSwatch = {
  10: "#001F24",
  20: "#00363D",
  30: "#054E57",
  40: "#2A676F",
  50: "#458089",
  60: "#609AA3",
  70: "#7AB5BE",
  80: "#96D0DA",
  90: "#B1ECF7",
  93: "#BBF5FF",
  95: "#D0F8FF",
  98: "#EDFCFF",
}

export const seafoam: ColorSwatch = {
  10: "#00201F",
  20: "#003736",
  30: "#00504E",
  40: "#206866",
  50: "#3E827F",
  60: "#599C99",
  70: "#74B7B4",
  80: "#8FD2CF",
  90: "#ABEFEB",
  93: "#B3F8F4",
  95: "#B9FDFA",
  98: "#E3FFFD",
}

export const midnight: ColorSwatch = {
  10: "#13183E",
  20: "#282D54",
  30: "#3F446C",
  40: "#565B86",
  50: "#6F74A0",
  60: "#898EBB",
  70: "#A4A8D7",
  80: "#BFC3F4",
  90: "#DFE0FF",
  93: "#EAE9FF",
  95: "#F1EFFF",
  98: "#FBF8FF",
}

export const sky: ColorSwatch = {
  10: "#001D36",
  20: "#003258",
  30: "#00497D",
  40: "#0961A2",
  50: "#347ABD",
  60: "#5394D9",
  70: "#6FAFF5",
  80: "#9ECAFF",
  90: "#D1E4FF",
  93: "#E0ECFF",
  95: "#EAF1FF",
  98: "#F8F9FF",
}

export const coal: ColorSwatch = {
  10: "#101D24",
  20: "#253239",
  30: "#3B4950",
  40: "#536068",
  50: "#6B7981",
  60: "#85939B",
  70: "#9FADB6",
  80: "#BBC9D1",
  90: "#D6E5EE",
  93: "#DFEDF6",
  95: "#E5F3FC",
  98: "#F4FAFF",
}

export const grey: ColorSwatch = {
  10: "#1B1B1B",
  20: "#303030",
  30: "#474747",
  40: "#5E5E5E",
  50: "#777777",
  60: "#919191",
  70: "#ABABAB",
  80: "#C6C6C6",
  90: "#E2E2E2",
  93: "#EBEBEB",
  95: "#F1F1F1",
  98: "#F9F9F9",
}

export const primitives: Primitives = {
  prawn,
  amber,
  sand,
  sol,
  spruce,
  fern,
  evergreen,
  seafoam,
  midnight,
  sky,
  coal,
  grey,
}

// Define the structure of named primitives
type NamedPrimitives = {
  [colorName: string]: {
    [keyName: number]: string // Example: { "prawn10": "#fff", "prawn20": "#eee", ... }
  }
}

type NamedPrimitivesFlat = {
  [keyName: string]: string // Example: { "prawn10": "#fff", "amber10": "#eee", ... }
}

// Generate named primitives
const namedPrimitives: NamedPrimitives = {}
const namedPrimitivesFlat: NamedPrimitivesFlat = {}
const colorNames = Object.keys(primitives) as Array<keyof typeof primitives>

colorNames.forEach((colorName) => {
  const colorSwatch: ColorSwatch = { ...(primitives[colorName] as ColorSwatch) }
  const colorGrades = Object.keys(colorSwatch)
  const namedSwatch: { [key: string]: string } = {}

  colorGrades.forEach((colorGrade) => {
    const gradeNumber = Number(colorGrade)
    const keyName = `${colorName}${gradeNumber}`
    const value = colorSwatch[gradeNumber]

    namedSwatch[keyName] = value
    namedPrimitivesFlat[keyName] = value
  })

  namedPrimitives[colorName] = namedSwatch
})

export { namedPrimitives, namedPrimitivesFlat }
