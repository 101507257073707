export enum MealType {
  Breakfast = "breakfast",
  Lunch = "lunch",
  Dinner = "dinner",
  Snack = "snack",
  Unspecified = "unspecified",
}

export type NutritionData = {
  calories: number
  proteins: number
  fats: number
  carbohydrates: number
}

export type Ingredient = {
  ingredient: string
  quantity: number
  unit: string
}

export type FoodItem = {
  id: string
  food: string
  source: string
  timeframe: string
  text: string
  meal: MealType
  mealId: string
  unit: string
  size: string
  quantity: number
  modifiers: string[]
  ingredients: Ingredient[]
  createdAt: Date
  updatedAt: Date
  consumedAt: Date | null
  confirmedAt: Date | null
  messageId: string | null
  threadId: string | null
  nutritionData: NutritionData | null
}

export type Meal = {
  id: string
  displayName: string
  createdAt: Date
  updatedAt: Date
  consumedAt: Date
  timeframe: string
  items: FoodItem[]
}

export type GetMealsResponse = {
  meals: Meal[]
}

export type FoodSummaryResponse = {
  hasHistoricalData: boolean
  mealCount: number | null
}

export type DeleteEntityFeedback = {
  deleteReason: string | null
  deleteComment: string | null
}

export type MealData = {
  displayName: MealType
  items: FoodItem[]
  nutritionData: NutritionData
}

export type DailyFoodItem = {
  day: string
  meals: MealData[]
  nutritionData: NutritionData
}

// Non-API type: This is used to group meals by meal type for food profile screen
export type MealTypeGroup = {
  mealType: MealType
  meal: MealData
}
