import { create } from "./create"

export const useAppStore = create<{
  threadListOpen: boolean
  setThreadListOpen: (open: boolean) => void

  bottomNavHeight: number
  setBottomNavHeight: (height: number) => void

  entityDrawerOpen: boolean
  setEntityDrawerOpen: (open: boolean) => void

  selectedEntityId: string | null
  setSelectedEntityId: (id: string | null) => void

  exitOnLogout: boolean
  setExitOnLogout: (exitOnLogout: boolean) => void

  datePickerDate: Date
  setDatePickerDate: (date: Date) => void

  authenticationStartTime: Date | null
  setAuthenticationStartTime: (time: Date | null) => void
}>((set) => {
  const setEntityDrawerOpen = (open: boolean) => {
    set({
      entityDrawerOpen: open,
    })
  }

  return {
    threadListOpen: true,
    setThreadListOpen: (open: boolean) =>
      set({
        threadListOpen: open,
      }),

    bottomNavHeight: 0,
    setBottomNavHeight: (height: number) =>
      set({
        bottomNavHeight: height,
      }),

    entityDrawerOpen: false,
    setEntityDrawerOpen,

    selectedEntityId: null,
    setSelectedEntityId: (id: string | null) => {
      if (id !== null) {
        setEntityDrawerOpen(true)
      }

      set({
        selectedEntityId: id,
      })
    },

    exitOnLogout: false,
    setExitOnLogout: (exitOnLogout: boolean) => {
      set({
        exitOnLogout,
      })
    },

    datePickerDate: new Date(),
    setDatePickerDate: (date: Date) => {
      set({
        datePickerDate: date,
      })
    },

    authenticationStartTime: null,
    setAuthenticationStartTime: (time: Date | null) => {
      set({
        authenticationStartTime: time,
      })
    },
  }
})
