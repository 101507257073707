import { useAuth } from "@my/api"
import { useMemo } from "react"
import { resetAllStores } from "../global-state"
import { mixpanel } from "../telemetry/mixpanel"

export enum LogoutType {
  User = "User",
  EnvSwitch = "Environment Switch",
  AuthIssue = "Auth Issue",
  Reinstall = "Reinstall",
  BackendError = "Backend Error",
}

export const useLogout = () => {
  const { logout } = useAuth()

  return useMemo(
    () => ({
      handleLogout: async (type: LogoutType) => {
        // Finish a logout before clearing out zustand stores
        await logout()
        resetAllStores()

        mixpanel.track("Logout", { type })
      },
    }),
    [logout],
  )
}
