import { HealthyLogo } from "app/assets/images/HealthyLogo"
import { FC } from "react"
import { Pressable } from "react-native"
import { Link } from "solito/link"
import { View, SizableText, TextProps, useMedia, XStack } from "tamagui"
import { AppleAppStoreWidget } from "@my/ui/src/assets/appleAppStoreWidget/AppleAppStoreWidget"
import { AndroidAppStoreWidget } from "@my/ui/src/assets/androidAppStoreWidget/AndroidAppStoreWidget"
import { trackButtonClicked } from "app/telemetry"
import { LEGAL_URLS } from "@my/api/src/constants"

type LinkData = {
  key: string
  label: string
  url: string
}

export const AppFooter: FC = () => {
  const { sm } = useMedia()

  const primaryLinks: LinkData[] = [
    {
      key: "About",
      label: "About",
      url: LEGAL_URLS.ABOUT,
    },
  ]

  const legalLinks: LinkData[] = [
    {
      key: "MhmdAct",
      label: "MHMD Act",
      url: LEGAL_URLS.MHMD_ACT,
    },
    {
      key: "PrivacyPolicy",
      label: "Privacy Policy",
      url: LEGAL_URLS.PRIVACY_POLICY,
    },
    {
      key: "TermsofService",
      label: "Terms of Service",
      url: LEGAL_URLS.TERMS_OF_USE,
    },
    {
      key: "QualityandSafety",
      label: "Quality and Safety",
      url: LEGAL_URLS.QUALITY_SAFETY,
    },
  ]

  return (
    <View
      backgroundColor="$backgroundPrimary"
      height={300}
      paddingHorizontal="$base"
      marginTop="auto"
    >
      <View
        alignItems={sm ? "center" : "flex-start"}
        justifyContent={sm ? "center" : "flex-start"}
        paddingVertical={24}
      >
        <HealthyLogo style={{ height: 44, width: 150 }} />
      </View>

      <View
        flexDirection="row"
        flexWrap="wrap"
        gap="$base"
        marginBottom="$sm"
        justifyContent={sm ? "center" : "flex-start"}
      >
        {primaryLinks.map((linkData, index) => (
          <FooterLink linkData={linkData} key={index} fontSize="$6" />
        ))}
      </View>

      <View
        flexDirection="row"
        flexWrap="wrap"
        gap="$sm"
        marginBottom="$base"
        justifyContent={sm ? "center" : "flex-start"}
      >
        {legalLinks.map((linkData, index) => (
          <FooterLink linkData={linkData} key={index} />
        ))}
      </View>

      <XStack
        justifyContent={sm ? "center" : "flex-start"}
        marginTop="$base"
        alignItems={sm ? "center" : "flex-start"}
        gap="$sm"
      >
        <Link
          target="_blank"
          href="https://apps.apple.com/us/app/healthy-holistic-health-coach/id6473211484"
        >
          <AppleAppStoreWidget height={41} width={120} />
        </Link>

        <Link
          target="_blank"
          href="https://play.google.com/store/apps/details?id=com.closedloop.healthcoach"
        >
          <AndroidAppStoreWidget height={41} width={120} />
        </Link>
      </XStack>
    </View>
  )
}

interface FooterLinkProps extends TextProps {
  linkData: LinkData
}

export const FooterLink: FC<FooterLinkProps> = ({ linkData, fontSize = "$3" }) => (
  <Pressable onPress={() => trackButtonClicked(linkData.key, "SettingsScreen")}>
    <Link href={linkData.url} target="_blank">
      <SizableText fontSize={fontSize} color="$textLink" textDecorationLine="underline">
        {linkData.label}
      </SizableText>
    </Link>
  </Pressable>
)
