import { createInterFont } from "@tamagui/font-inter"
import { createFont } from "tamagui"

/**
 * Shared font settings.
 *
 * Note: The tokens with comments are the default tokens for those elements via Tamagui.
 */
const sharedFontSettings = {
  color: {
    1: "$color",
    2: "$color",
    3: "$color",
    4: "$color", // ListItem.Text, Label, Paragraph
    5: "$color", // H6 & Text
    6: "$color", // H5
    7: "$color", // H4
    8: "$color", // H3
    9: "$color", // H2
    10: "$color", // H1
    11: "$color",
  },
  // General reset of letter spacing
  letterSpacing: {
    1: 0,
    2: 0,
    3: 0,
    4: 0, // ListItem.Text, Label, Paragraph
    5: 0, // H6 & Text
    true: 16,
    6: 0, // H5
    7: 0, // H4
    8: 0, // H3
    9: 0, // H2
    10: 0, // H1
    11: 0,
    12: 0,
    13: 0,
    14: 0,
    15: 0,
  },
  // Size in pixels
  size: {
    1: 13,
    2: 13,
    3: 13,
    4: 13, // ListItem.Text, Label, Paragraph
    5: 16, // H6 & Text
    true: 16,
    6: 16, // H5
    7: 18, // H4
    8: 20, // H3
    9: 24, // H2
    10: 39, // H1
    11: 55,
    12: 72,
    13: 90,
    14: 109,
    15: 129,
    16: 150,
    17: 172,
    18: 195,
    19: 219,
    20: 244,
  },
  weight: {
    1: "500",
    2: "500",
    3: "500",
    4: "500", // ListItem.Text, Label, Paragraph
    5: "500", // H6 & Text
    true: "500",
    6: "700", // H5
    7: "700", // H4
    8: "700", // H3
    9: "700", // H2
    10: "700", // H1
    11: "700",

    400: "400",
    normal: "400",
    500: "500",
    medium: "500",
    600: "600",
    semibold: "600",
    700: "700",
    bold: "700",
  },

  // for native
  face: {
    400: { normal: "Inter", italic: "InterItalic" },
    500: { normal: "InterMedium", italic: "InterMediumItalic" },
    600: { normal: "InterSemiBold", italic: "InterSemiBoldItalic" },
    700: { normal: "InterBold", italic: "InterBoldItalic" },
  },
}

export const headingFont = createInterFont(
  {
    ...sharedFontSettings,
    weight: {
      ...sharedFontSettings.weight,
      5: "700", // H6
    },
  },
  {
    // Round font size, no fractional px on web.
    sizeSize: (size) => Math.round(size),
    // responsive line height aligning with font size
    sizeLineHeight: (size) => Math.round(size) * 1.2,
  },
)

export const bodyFont = createInterFont(
  {
    ...sharedFontSettings,
    size: {
      ...sharedFontSettings.size,
      6: 18, // Body Emphasized
    },
  },
  {
    // Round font size, no fractional px on web.
    sizeSize: (size) => Math.round(size),
    // responsive line height aligning with font size
    sizeLineHeight: (size) => Math.round(size) * 1.5,
  },
)

export const fieldsFont = createFont({
  family: "Fields",
  size: {
    1: 39,
    2: 70,
    3: 128,
  },
  weight: {
    1: "600",
    2: "700",
  },
  face: {
    600: { normal: "FieldsSemiBold" },
    700: { normal: "FieldsBold" },
  },
})
