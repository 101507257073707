import { TermsAndConditions } from "./TermsAndConditions"
import { LoginFlow } from "./LoginFlow"
import { isWeb } from "tamagui"
import { useUserStore } from "app/global-state/userState"
import { useStorage, useStoredBoolean } from "app/storage/useStorage"
import { TimezoneListener } from "./TimezoneListener"
import { useAuth, useHttpClient } from "@my/api"
import LoggedOut from "./LoggedOut"
import { useToggleSubscription } from "app/hooks/useSubscriptions"
import { useEffect, useRef, useState } from "react"
import { writeToDebug } from "@my/config/src/debug"
import { useSubscriptionStore } from "app/global-state/subscriptionsStore"

export const StartupFlow = ({ children }: { children: React.ReactNode }) => {
  const { tosAccepted, setTosAccepted } = useUserStore()
  const { isAuthenticated, isLoading } = useAuth()
  const [hasAcceptedTerms, setHasAcceptedTerms] = useStoredBoolean("hasAcceptedTerms", false)

  useHandlePendingModuleSubscription()

  useEffect(() => {
    if (!hasAcceptedTerms && tosAccepted) {
      setHasAcceptedTerms(true)
      return
    }
  }, [hasAcceptedTerms, tosAccepted])

  /*
   * The !isLoading check prevents logged out from showing on browser refresh but also ends up keeping logout page
   * from showing while a logout is in progress since isLoading will be true. User just sees LoginFlow which is fine.
   */
  if (isWeb && !isLoading && !isAuthenticated) {
    return <LoggedOut>{children}</LoggedOut>
  }

  return (
    <LoginFlow>
      {isAuthenticated && !tosAccepted ?
        <TermsAndConditions consented={tosAccepted} setConsented={setTosAccepted}>
          <TimezoneListener>{children}</TimezoneListener>
        </TermsAndConditions>
      : <TimezoneListener>{children}</TimezoneListener>}
    </LoginFlow>
  )
}

/**
 * Handles the pending module subscription.
 *
 * This hook will check if there is a pending module subscription in storage and if so, it will subscribe to it.
 * This supports subscribing to a module while logged out.
 */
function useHandlePendingModuleSubscription() {
  const storage = useStorage()
  const httpClient = useHttpClient()
  const { isAuthenticated, isLoading } = useAuth()
  const toggleSubscription = useToggleSubscription()
  const { fetchSubscriptions } = useSubscriptionStore()
  const { user } = useUserStore()

  const [pendingModuleSubscriptionId, setPendingModuleSubscriptionId] = useState(() =>
    storage.getString("pendingModuleSubscriptionId"),
  )
  const [fetchedSubscriptions, setFetchedSubscriptions] = useState(false)
  const handledPendingSubscriptionRef = useRef(false)

  useEffect(() => {
    // If we're not authenticated or there's no pending module subscription, we can skip
    if (
      !isAuthenticated ||
      isLoading ||
      !pendingModuleSubscriptionId ||
      !httpClient.isInitialized ||
      !user
    ) {
      return
    }

    ;(async () => {
      /*
       * When a user logs in, we need to fetch subscriptions so we know if they are already subscribed to a module.
       * If they are, we can skip calling addSub in toggleSubscription(). I didn't want toggleSubscription() to have to
       * also do a fetch so we can keep it SRP (Single Responsibility Principle).
       */
      await fetchSubscriptions(httpClient)
      setFetchedSubscriptions(true)
    })()
  }, [
    user,
    isAuthenticated,
    isLoading,
    httpClient,
    pendingModuleSubscriptionId,
    fetchSubscriptions,
  ])

  useEffect(() => {
    // Since setPendingModuleSubscriptionId() is async, this helps avoid a secondary call to toggleSubscription()
    if (handledPendingSubscriptionRef.current) {
      return
    }

    /*
     * Check if we're still fetching subscriptions. We don't really care if we succesfully fetched or not
     * as the backend will handle it gracefully if we call add sub for an existing subscription. We'll give it one
     * attempt at least.
     */
    if (!fetchedSubscriptions) {
      return
    }

    // Make sure we're authenticated before proceeding
    if (!isLoading && isAuthenticated && pendingModuleSubscriptionId) {
      handledPendingSubscriptionRef.current = true
      ;(async () => {
        writeToDebug("Identified a pending module subscription, subscribing...")
        await toggleSubscription(true, pendingModuleSubscriptionId)

        storage.delete("pendingModuleSubscriptionId")
        setPendingModuleSubscriptionId("")
      })()
    }
  }, [
    isAuthenticated,
    isLoading,
    pendingModuleSubscriptionId,
    storage,
    fetchedSubscriptions,
    toggleSubscription,
  ])
}
