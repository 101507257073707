import { AuthEvents, useAuth } from "@my/api"
import { EventBus } from "app/events"
import { ExploreProgramsPage } from "app/features/programs"
import { useAppStore } from "app/global-state/appStore"
import { Routes } from "app/routing"
import { mixpanel } from "app/telemetry/mixpanel"
import { useRouter, useSearchParams } from "next/navigation"
import { useEffect, useMemo } from "react"

export default function HomePage() {
  const router = useRouter()
  const params = useSearchParams()
  const { isAuthenticated } = useAuth()
  const { authenticationStartTime, setAuthenticationStartTime } = useAppStore()

  const authEvent = useMemo(() => {
    // After the user logs in, Auth0 redirects to "/" with code and state params present.
    if (params.get("code") && params.get("state")) {
      return AuthEvents.Login
    }
    return null
  }, [params])

  useEffect(() => {
    if (authEvent) {
      EventBus.emit(authEvent)

      mixpanel.track("Login", {
        success: true,
        duration:
          authenticationStartTime ?
            new Date().getTime() - authenticationStartTime.getTime()
          : undefined,
      })
      setAuthenticationStartTime(null)
    }
  }, [authEvent, authenticationStartTime])

  // If the user is authenticated or is in the process of logging in, redirect to the chat page.
  if (isAuthenticated || authEvent) {
    router.replace(Routes.home)
    return null
  }

  return <ExploreProgramsPage />
}
