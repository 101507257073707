import { HealthDataPoint } from "./healthProfile"
import { FoodItem } from "./food"

export type UserRole = "user" | "assistant" | "cl"
// TODO: Update the message types once we decide on the variants.
export type MessageType = "user" | "assistant" | "cl"

export type Message = {
  id: string
  threadId: string
  content: string
  messageType: MessageType
  role: UserRole
  feedbackType: string | null
  createdAt: Date
  entities?: EntityAnnotation[]
}

export type EntityAnnotation = {
  id: string
  text: string
  confirmedAt: Date | null
}

export enum NewThreadType {
  CheckIn = "start_check_in",
  Starter = "use_conversation_starter",
  ChartWithData = "start_conversation_on_chart_data",
  ChartNoData = "start_conversation_on_chart_no_data",
}

export type NewMessage = {
  threadId?: string
  clientId: string
  content: string
}

export type NewConversationWithStarter = {
  type: NewThreadType.Starter
  conversationStarterId: string
}

export type WSMessageStart = {
  type: WSMessageType.start
  threadId: string
  replyToId?: string
  replyToClientId?: string
  messageType: MessageType
  role?: UserRole
}

export type WSAssistantResponseStart = {
  type: WSMessageType.assistantResponseStart
  threadId: string
  replyToId?: string
  replyToClientId?: string
  messageType: MessageType
  role?: UserRole
}

export type WSFullMessage = {
  type: WSMessageType.fullMessage
  messageId?: string
  threadId: string
  messageType: MessageType
  role?: UserRole
  content: string
}

export type WSMessageUpdated = {
  type: WSMessageType.messageUpdated
  id: string
  threadId: string
  messageType: MessageType
  role?: UserRole
  content: string
}

export type WSMessageContent = {
  type: WSMessageType.content
  content: string
  threadId: string
}

export type WSMessageEnd = {
  type: WSMessageType.end
  messageId?: string
  threadId: string
  hash: string
}

export type WSThreadUpdated = {
  type: WSMessageType.threadUpdated
  id: string
  name: string
}

export type WSObjectCreated = {
  type: WSMessageType.objectCreated
  id: string
  threadId: string
  objectType: "goal"
}

/**
 * Enum representing the different types of entities that can be received as socket events.
 */
export enum EntityType {
  FoodData = "FoodDataEntity",
  HealthData = "HealthDataPoint",
}

export type WSHealthEntitiesExtracted = {
  type: WSMessageType.entitiesExtracted
  entityType: EntityType.HealthData
  entities: HealthDataPoint[]
}

export type WSFoodEntitiesExtracted = {
  type: WSMessageType.entitiesExtracted
  entityType: EntityType.FoodData
  entities: FoodItem[]
}

export type WSMessage =
  | WSMessageStart
  | WSMessageContent
  | WSMessageEnd
  | WSAssistantResponseStart
  | WSFullMessage
  | WSObjectCreated
  | WSMessageUpdated
  | WSHealthEntitiesExtracted
  | WSFoodEntitiesExtracted

export type MessageResponse = {
  content: string
  createdAt: Date
  entities: EntityAnnotation[]
  extra: string
  id: string
  messageType: MessageType
  rating: number
  role: UserRole
  seen: boolean
  threadId: string
  tokens: number
}

export enum WSMessageType {
  start = "message_start",
  assistantResponseStart = "assistant_response_start",
  content = "message",
  end = "message_end",
  fullMessage = "full_message",
  objectCreated = "object_created",
  messageUpdated = "message_updated",
  threadUpdated = "thread_updated",
  entitiesExtracted = "entities_extracted",
}
