import closedLoopTheme from "./themes/closedloop/closedloop-v1"

/**
 * Note: dark mode is currently disabled globally while we finish the dark mode styling.
 * For now, both light and dark mode will return light mode theme color.
 *
 * To turn it dark mode back on, remove line 12 and uncomment the real implementation.
 * You will also need to adjust the useDarkMode hook.
 */
export const themes = {
  light: closedLoopTheme.light,
  dark: closedLoopTheme.light,
  // dark: closedLoopTheme.dark,
}
