import { Thread } from "@my/api"
import { create } from "app/global-state"

export const useThreadStore = create<{
  threads: Thread[]
  setThreads: (threads: Thread[]) => void
  addThread: (thread: Thread) => void
  removeThread: (threadId: string) => void
  updateThreadName: (threadId: string, name: string) => void
  activeThreadId: string | null
  setActiveThreadId: (threadId: string | null) => void
}>((set) => ({
  threads: [],
  setThreads: (threads: Thread[]) => set({ threads }),
  addThread: (thread: Thread) => set((state) => ({ threads: [...state.threads, thread] })),
  removeThread: (threadId: string) =>
    set((state) => ({ threads: state.threads.filter((t) => t.id !== threadId) })),
  updateThreadName: (threadId: string, name: string) =>
    set((state) => ({
      threads: state.threads.map((t) => (t.id === threadId ? { ...t, name } : t)),
    })),
  activeThreadId: null,
  setActiveThreadId: (threadId: string | null) => set({ activeThreadId: threadId }),
}))
