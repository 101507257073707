import { FC, useState, useMemo, useCallback, memo } from "react"
import { Image, ImageProps, View, ViewProps, isWeb } from "tamagui"
import { Icon } from "../icon/Icon"
import { ImageSourcePropType } from "react-native"

const DEBUG_IMAGE_LOADING = false

export interface PlaceholderImageProps extends ViewProps {
  backgroundColor?: ViewProps["backgroundColor"]
  iconColor?: string
  height?: number
  width?: number
}

export const PlaceholderImage: FC<PlaceholderImageProps> = ({
  backgroundColor = "$spruce50",
  iconColor = "$spruce80",
  height = 500,
  width = 700,
  ...props
}) => (
  <View
    alignContent="center"
    alignItems="center"
    backgroundColor={backgroundColor as ViewProps["backgroundColor"]}
    justifyContent="center"
    height={height}
    width={width}
    borderRadius="$6"
    {...props}
  >
    <Icon
      icon="image-landscape"
      color={iconColor}
      hoveringColor={iconColor}
      activeColor={iconColor}
      {...(isWeb ? { style: { height: "50%", width: "60%" } } : { size: width * 0.42 })}
    />
  </View>
)

export interface ImageWithPlaceholderProps {
  placeholderProps: PlaceholderImageProps
  imageProps: ImageProps
  index?: number
  onLoad?: (index?: number) => void
  previousImageLoaded?: boolean
}

function getImageUrl(source: ImageSourcePropType | undefined): string | undefined {
  if (!source) return undefined
  if (typeof source === "string") return source
  if (typeof source === "number") return `[local asset ${source}]`
  if (Array.isArray(source)) return `[multiple sources: ${source.length}]`
  if (source && typeof source === "object" && "uri" in source) return source.uri
  return undefined
}

export const ImageWithPlaceholder: FC<ImageWithPlaceholderProps> = memo(
  ({ placeholderProps, imageProps, index, onLoad, previousImageLoaded = true }) => {
    const [state, setState] = useState({
      imageError: false,
      hasLoaded: false,
      isLoading: false,
    })

    const imageUrl = useMemo(() => getImageUrl(imageProps.source), [imageProps.source])

    const handleLoadStart = useCallback(() => {
      if (state.hasLoaded || state.isLoading) return

      setState((prev) => ({ ...prev, isLoading: true }))
      if (DEBUG_IMAGE_LOADING) {
        console.log(`⏳ Image ${index} fetch started - URL: ${imageUrl}`)
      }
    }, [index, imageUrl, state.isLoading, state.hasLoaded])

    const handleLoad = useCallback(() => {
      if (state.hasLoaded) return

      if (DEBUG_IMAGE_LOADING) {
        console.log(`✅ Image ${index} loaded successfully - URL: ${imageUrl}`)
      }
      setState((prev) => ({
        ...prev,
        hasLoaded: true,
        isLoading: false,
      }))
      onLoad?.(index)
    }, [index, imageUrl, onLoad, state.hasLoaded])

    const handleError = useCallback(
      (error: any) => {
        console.error(`❌ Image ${index} failed to load - URL: ${imageUrl}`, error)
        setState((prev) => ({ ...prev, imageError: true }))
      },
      [index, imageUrl],
    )

    // Stable styles object
    const imageStyles = useMemo(
      () => [
        imageProps.style,
        {
          position: (state.hasLoaded && !state.imageError ? "relative" : "absolute") as
            | "relative"
            | "absolute",
          opacity: state.hasLoaded && !state.imageError ? 1 : 0,
          display: state.imageError ? "none" : "flex",
        },
      ],
      [imageProps.style, state.hasLoaded, state.imageError],
    )

    return (
      <View borderRadius="$6" overflow="hidden" testID="placeholder-image">
        {(!state.hasLoaded || state.imageError) && <PlaceholderImage {...placeholderProps} />}

        {previousImageLoaded && (
          <Image
            {...imageProps}
            style={imageStyles}
            onLoadStart={handleLoadStart}
            onLoad={handleLoad}
            onError={handleError}
          />
        )}
      </View>
    )
  },
  (prevProps, nextProps) => {
    const prevUrl = getImageUrl(prevProps.imageProps.source)
    const nextUrl = getImageUrl(nextProps.imageProps.source)

    const areEqual =
      prevProps.previousImageLoaded === nextProps.previousImageLoaded &&
      prevProps.index === nextProps.index &&
      prevUrl === nextUrl

    return areEqual
  },
)
