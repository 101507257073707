import { create as _create } from "zustand"
import type { StateCreator } from "zustand"

export function create<T>(stateCreator: StateCreator<T>) {
  const store = _create(stateCreator)
  const initialState = store.getState()
  storeResetFns.add(() => {
    store.setState(initialState, true)
  })
  return store
}

export function resetAllStores() {
  console.debug("Reseting all zustand state")
  storeResetFns.forEach((resetFn) => {
    resetFn()
  })
}

export function registerStoreResetFn(fn: () => void) {
  storeResetFns.add(fn)
}

const storeResetFns = new Set<() => void>()
