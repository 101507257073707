import healthyColors from "../healthyColors"
import { namedPrimitivesFlat as primitives } from "../primitives"

export const light = {
  ...primitives,
  ...healthyColors,

  background: primitives.sand98,
  backgroundBanner: primitives.sky90,
  backgroundCardsHover: primitives.spruce80,
  backgroundCardsPress: primitives.spruce95,
  backgroundTip: primitives.spruce93,
  backgroundDetail: "white",
  backgroundEmphasis: primitives.sand95,
  backgroundError: primitives.prawn50,
  backgroundFocus: "white",
  backgroundHover: primitives.grey93,
  backgroundInfo: primitives.sky60,
  backgroundInput: "white",
  backgroundInverse: primitives.grey10,
  backgroundMessage: primitives.spruce80,
  backgroundNeutral: primitives.grey90,
  backgroundOverlay: "rgba(0, 0, 0, 0.48)",
  backgroundPanePrimary: primitives.sky98,
  backgroundPaneSecondary: primitives.sky98,
  backgroundPress: primitives.grey93,
  backgroundPrimary: primitives.grey95,
  backgroundSecondary: "white",
  backgroundSecondaryNav: "white",
  backgroundSelected: primitives.spruce80,
  backgroundSuccess: primitives.fern60,
  backgroundSuccessLight: primitives.fern80,
  backgroundSwitchOff: primitives.coal80,
  backgroundSwitchOn: healthyColors.action,
  backgroundWarning: primitives.amber70,
  backgroundWarningLight: primitives.amber90,

  borderColor: primitives.grey80,
  borderColorHover: primitives.grey70,
  borderColorPress: primitives.grey60,
  borderColorFocus: healthyColors.action,

  color: primitives.grey10,
  colorTitle: primitives.evergreen30,
  colorInverse: primitives.grey98,
  colorHover: primitives.grey10,
  colorPress: primitives.grey10,
  colorFocus: primitives.grey10,

  shadowColor: primitives.grey60,
  shadowColorHover: primitives.grey60,
  shadowColorPress: primitives.grey60,
  shadowColorFocus: primitives.grey60,

  borderContainer: primitives.grey80,
  borderError: primitives.prawn50,
  borderWarning: primitives.amber60,
  borderFocus: healthyColors.tertiary,
  borderSelected: primitives.sky40,

  buttonPrimary: healthyColors.primary,
  buttonPrimaryHover: primitives.evergreen60,
  buttonPrimaryPress: primitives.evergreen40,
  buttonPrimaryFocus: primitives.evergreen60,
  buttonPrimaryDisabled: primitives.grey80,
  buttonPrimaryText: primitives.grey98,
  buttonDisabledPrimaryText: primitives.grey40,

  buttonSecondary: "#D0E7E6",
  buttonSecondaryHover: primitives.grey90,
  buttonSecondaryPress: primitives.grey80,
  buttonSecondaryFocus: primitives.grey90,
  buttonSecondaryDisabled: primitives.grey95,
  buttonSecondaryText: primitives.grey10,
  buttonDisabledSecondaryText: primitives.grey50,

  buttonTertiary: "transparent",
  buttonTertiaryBorder: primitives.grey80,
  buttonTertiaryHover: primitives.grey90,
  buttonTertiaryPress: primitives.grey80,
  buttonTertiaryFocus: primitives.grey90,
  buttonTertiaryDisabled: "transparent",
  buttonTertiaryText: primitives.grey10,
  buttonDisabledTertiaryText: primitives.grey50,

  buttonAction: primitives.sol80,
  buttonActionHover: primitives.sol70,
  buttonActionPress: primitives.sol60,
  buttonActionFocus: primitives.sol60,
  buttonActionDisabled: primitives.grey80,
  buttonActionText: primitives.grey98,
  buttonDisabledActionText: primitives.grey40,

  icon: primitives.grey10,
  iconDark: primitives.grey98,
  iconLight: primitives.grey10,
  iconSecondary: primitives.grey40,
  iconError: primitives.prawn50,
  iconWarning: primitives.amber60,
  iconSuccess: primitives.fern60,
  iconInfo: primitives.sky40,
  iconSelected: primitives.sky50,
  iconAction: primitives.sky50,
  iconDangerous: primitives.prawn50,
  iconDisabled: primitives.grey80,
  iconBrand: healthyColors.primary,
  iconBeta: primitives.sol80,

  text: primitives.grey10,
  textInverse: primitives.grey93,
  textHover: primitives.grey10,
  textPress: primitives.grey10,
  textFocus: primitives.grey10,
  textSecondary: primitives.grey40,
  textDisabled: primitives.grey80,
  textDark: primitives.grey98,
  textLight: primitives.grey10,
  textLink: primitives.sky50,
  textAction: primitives.sky50,
  textSelected: primitives.grey10,
  textDangerous: primitives.prawn50,
  textWarning: primitives.amber50,
  textSuccess: primitives.fern40,
  textOnTertiary: primitives.seafoam20,
  textBeta: primitives.sol40,

  chartAxis: primitives.grey80,
  chartGrid: primitives.grey80,
  chartGridDark: primitives.grey80,
  chartLabel: primitives.grey40,
  chartTicks: primitives.grey80,
  chartTicksLabels: primitives.grey40,
  chartDataBorder: primitives.grey80,
  chartDataPrimary: primitives.sky80,
  chartDataWarning: primitives.amber70,

  switchOff: primitives.coal80,
  switchOn: healthyColors.action,
  switchThumb: primitives.midnight98,
}

export type BaseTheme = typeof light

export const dark: BaseTheme = {
  ...primitives,
  ...healthyColors,

  background: primitives.grey10,
  backgroundBanner: primitives.sky90,
  backgroundEmphasis: primitives.sand95,
  backgroundCardsHover: primitives.spruce20,
  backgroundCardsPress: primitives.sand40,
  backgroundTip: primitives.spruce93,
  backgroundDetail: primitives.grey20,
  backgroundError: primitives.prawn50,
  backgroundFocus: "white",
  backgroundHover: primitives.grey10,
  backgroundInfo: primitives.sky60,
  backgroundInput: primitives.grey30,
  backgroundInverse: primitives.grey10,
  backgroundMessage: primitives.spruce10,
  backgroundNeutral: primitives.grey90,
  backgroundOverlay: "rgba(0, 0, 0, 0.48)",
  backgroundPanePrimary: primitives.sky98,
  backgroundPaneSecondary: primitives.sky98,
  backgroundPress: primitives.grey10,
  backgroundPrimary: primitives.grey30,
  backgroundSwitchOff: primitives.coal80,
  backgroundSwitchOn: healthyColors.action,
  backgroundSecondary: primitives.grey20,
  backgroundSecondaryNav: "white",
  backgroundSelected: primitives.sky20,
  backgroundSuccess: primitives.fern60,
  backgroundSuccessLight: primitives.fern50,
  backgroundWarning: primitives.amber70,
  backgroundWarningLight: primitives.amber90,

  borderColor: primitives.grey30,
  borderColorHover: primitives.grey20,
  borderColorPress: primitives.grey20,
  borderColorFocus: healthyColors.action,

  color: primitives.grey98,
  colorTitle: primitives.evergreen80,
  colorInverse: primitives.grey10,
  colorHover: primitives.grey80,
  colorPress: primitives.grey80,
  colorFocus: primitives.grey80,

  shadowColor: primitives.grey10,
  shadowColorHover: primitives.grey10,
  shadowColorPress: primitives.grey10,
  shadowColorFocus: primitives.grey10,

  borderContainer: primitives.grey30,
  borderError: primitives.prawn60,
  borderWarning: primitives.amber70,
  borderFocus: healthyColors.tertiary,
  borderSelected: primitives.sky40,

  buttonPrimary: healthyColors.primary,
  buttonPrimaryHover: primitives.evergreen60,
  buttonPrimaryPress: primitives.evergreen40,
  buttonPrimaryFocus: primitives.evergreen60,
  buttonPrimaryDisabled: primitives.grey80,
  buttonPrimaryText: primitives.grey98,
  buttonDisabledPrimaryText: primitives.grey40,

  buttonSecondary: "#D0E7E6",
  buttonSecondaryHover: primitives.grey90,
  buttonSecondaryPress: primitives.grey80,
  buttonSecondaryFocus: primitives.grey90,
  buttonSecondaryDisabled: primitives.grey95,
  buttonSecondaryText: primitives.grey10,
  buttonDisabledSecondaryText: primitives.grey50,

  buttonTertiary: "transparent",
  buttonTertiaryBorder: primitives.grey80,
  buttonTertiaryHover: primitives.grey90,
  buttonTertiaryPress: primitives.grey80,
  buttonTertiaryFocus: primitives.grey90,
  buttonTertiaryDisabled: "transparent",
  buttonTertiaryText: primitives.grey10,
  buttonDisabledTertiaryText: primitives.grey50,

  buttonAction: primitives.sol80,
  buttonActionHover: primitives.sol70,
  buttonActionPress: primitives.sol60,
  buttonActionFocus: primitives.sol60,
  buttonActionDisabled: primitives.grey80,
  buttonActionText: primitives.grey98,
  buttonDisabledActionText: primitives.grey40,

  icon: primitives.grey93,
  iconDark: primitives.grey10,
  iconLight: primitives.grey93,
  iconSecondary: primitives.grey60,
  iconError: primitives.prawn60,
  iconWarning: primitives.amber70,
  iconSuccess: primitives.fern98,
  iconInfo: primitives.sky60,
  iconSelected: primitives.sky60,
  iconAction: primitives.sky60,
  iconDangerous: primitives.prawn60,
  iconDisabled: primitives.grey40,
  iconBrand: healthyColors.primary,
  iconBeta: primitives.sol80,

  text: primitives.grey93,
  textInverse: primitives.grey10,
  textHover: primitives.grey80,
  textPress: primitives.grey80,
  textFocus: primitives.grey80,
  textSecondary: primitives.grey60,
  textDisabled: primitives.grey40,
  textDark: primitives.grey10,
  textLight: primitives.grey93,
  textLink: primitives.sky60,
  textAction: primitives.sky60,
  textSelected: primitives.grey98,
  textDangerous: primitives.prawn60,
  textWarning: primitives.amber50,
  textSuccess: primitives.fern40,
  textOnTertiary: primitives.seafoam20,
  textBeta: primitives.sol40,

  chartAxis: primitives.grey20,
  chartGrid: primitives.grey50,
  chartGridDark: primitives.grey70,
  chartLabel: primitives.grey60,
  chartTicks: primitives.grey50,
  chartTicksLabels: primitives.grey60,
  chartDataBorder: primitives.grey20,
  chartDataPrimary: primitives.sky80,
  chartDataWarning: primitives.amber70,

  switchOff: primitives.coal80,
  switchOn: healthyColors.action,
  switchThumb: primitives.midnight98,
}

export default {
  light,
  dark,
}
