// import { useThemeName } from "tamagui"

export const useIsDarkMode = () => {
  /**
   * Utility to check whether the app is in dark mode.
   *
   * Note: dark mode is currently disabled globally while we finish the dark mode styling.
   * To turn it on, remove the early return and uncomment the real implementation.
   * You will also need to adjust themes.ts
   */
  // const themeName = useThemeName()
  // return themeName == "dark"
  return false
}
