import { Routes } from "../../routing"
import { TabBar, TabProps } from "./components/TabBar"
import { useFeatureFlag } from "app/feature-flags/useFeatureFlag"

const tabs: TabProps[] = [
  { value: Routes.home, label: "Home", iconName: "house" },
  { value: Routes.chat, label: "Chat", iconName: "comment-lines" },
  { value: Routes.programs, label: "Explore", iconName: "compass" },
  { value: Routes.profile, label: "Profile", iconName: "user-circle" },
]

export function TabNavigator({ children }: { children: React.ReactNode }) {
  const modulesFlag = useFeatureFlag("release-modules", false)

  const filteredTabs = tabs.filter((tabDetails) => modulesFlag || tabDetails.label !== "Explore")

  return <TabBar tabs={filteredTabs}>{children}</TabBar>
}
